import { ChartTypes } from 'types/chart';
import { DataGrid } from 'types/dataGrid';
import { BatchRange, StatementRange } from 'types/reports';
import { CustomerId, ResultSortDirection } from 'types/user';

export enum Queries {
  Support = 'Support',
  Equipment = 'Equipment',
  Dashboard = 'Dashboard',
  CustomDataGrid = 'CustomDataGrid',
  EquipmentDetails = 'EquipmentDetails',
  EquipmentInfo = 'EquipmentInfo',
  EquipmentPackage = 'EquipmentPackage',
  LocationSearch = 'Location',
  Chart = 'Chart',
  LastDeposit = 'LastDeposit',
  GrossSales = 'GrossSales',
  PaymentTypeData = 'PaymentTypeData',
  User = 'User',
  ImpersonateUser = 'ImpersonateUser',
  SearchUsers = 'SearchUsers',
  FilteredCustomerAssignment = 'FilteredCustomerAssignment',
  UserManagement = 'UserManagement',
  CustomerAddress = 'CustomerAddress',
  CustomerAccountSettings = 'CustomerAccountSettings',
  Documents = 'Documents',
  Cart = 'Cart',
  Notifications = 'Notifications',
  Subscriptions = 'Subscriptions',
  GetClosedBatchSummaries = 'GetClosedBatchSummaries',
  GetOpenBatchSummaries = 'GetOpenBatchSummaries',
  GetTerminalNumbers = 'GetTerminalNumbers',
  ClosedBatchDetails = 'ClosedBatchDetails',
  OverrideTemplate = 'OverrideTemplate',
  GetOpenBatchAmount = 'GetOpenBatchAmountData',
  GetACHDetails = 'GetACHDetails',
  GetACHAmountTotalsByDayQuery = 'GetACHAmountTotalsByDayQuery',
  GetSalesRepresentative = 'GetSalesRepresentative',
  GetStatementReportData = 'GetStatementReportData',
  PendingOwners = 'PendingOwners',
}

export const UserQueryString = `activeCustomer
      {
          idType
          idValue
      }
      userEmail
      permissionSet
      {
        isRestricted
        name
        permissions
      }
      firstName
      lastName
      isVerified
      isMyAccountEligible
      isOwner
      isSigner
      active
      impersonateMode
      role
      overrideActive
      totalCustomerCount
      totalItemsInCart
      userHasActivePaymentsAccounts
      userHasActivePayrollAccounts
      assignedCustomerApplications
      {
          code
          navLinkUrl
      }
      activeCustomerInformation
      {
          city
          state
          customerHasActivePaymentsManagerPlus
          customerHasActivePayrollPlus
          customerEligibleForAccountUpdater
          customerTerminated
          dbaName
          highRisk
          isValidCartDbaState
          isValidCartDbaStatePr
          isValidCartLegalState
          isValidDbaAddress
          isValidLegalAddress
          isAddToCartAllowed
          ownerEmails
          paymentsCustomerType
          state
          sellerFirstName
          sellerLastName
          sellerWorkEmail
          sellerWorkPhone
          personOid
          mcc
      }
      pendingOwnershipVerifications
      {
        identifierValue
        identifierType
        dbaName
        remainingAttempts
        maximumAttempts
        verified
      }
      flags
      {
          showFundingReport
          showIntegrations
          showBenefitsAdministration
          showHiringAndOnBoarding
          showTimeAndAttendance
          showBillPay
          showAccountSettings
          showSGASimulator
          showCTACard
          showPermissionOverride
          showImpersonateUser
          showStatementReport
      }
      callToActions
      {
          addNewUser
      }
`;

export type Query = {
  Key: Queries;
  Query: (...params: any) => string;
};

export const ChartQuery = {
  Key: Queries.Chart,
  Query: (type: ChartTypes, minDate: string, maxDate: string) => `
  query {
    chartByName(
      param: {
        chartName:${type}
        minDate:"${minDate}"
        maxDate:"${maxDate}"
    }
    ){
      title
      dataPoints{
        xValue
        xValueFormatted
        yValue
        yValueFormatted
      }
      chartLabel{
        xLabel
        yLabel
      }
      units
    }
  }
  `,
};

export const LastDepositQuery = {
  Key: Queries.LastDeposit,
  Query: () => {
    return `
  query {
    lastDeposit
    {
      amount
      date
    }
  }
    `;
  },
};

export const GrossSalesQuery = {
  Key: Queries.GrossSales,
  Query: (range: BatchRange, minDate?: string, maxDate?: string) => {
    if (range === BatchRange.Custom)
      return `
query {
  grossSales(
    param: {
      range:${range}
      customMinDate: "${minDate}"
      customMaxDate: "${maxDate}"
    }
  )
  {
    grossSales
    trend
  }
}
    `;

    return `
query {
  grossSales(
    param: {
      range:${range}
    }
  )
  {
    grossSales
    trend
  }
}
  `;
  },
};

export const PaymentTypeDataQuery = {
  Key: Queries.PaymentTypeData,
  Query: (range: BatchRange, minDate?: string, maxDate?: string) => {
    if (range === BatchRange.Custom)
      return `
query {
  paymentTypeData(
    param: {
      range:${range}
      customMinDate: "${minDate}"
      customMaxDate: "${maxDate}"
    }
  )
  {
    paymentType,
    paymentTypePercentage,
    paymentTypeTotalAmount,
    paymentTypeTotalAmountFormatted
    index
  }
}
    `;

    return `
query {
  paymentTypeData(
    param: {
      range:${range}
    }
  )
  {
    paymentType,
    paymentTypePercentage,
    paymentTypeTotalAmount,
    paymentTypeTotalAmountFormatted
    index
  }
}
  `;
  },
};

export const CustomDataGridQuery = {
  Key: Queries.CustomDataGrid,
  Query: (dataGrid: DataGrid) => `
  query {
    customDataGrid (
        param:{
          name: "${dataGrid}"
        }
    ){
      name,
      configurationJSON       
    }
  }
  `,
};

export const CartQuery = {
  key: Queries.Cart,
  Query: (customerId: CustomerId) => `
 query{
    cart(
      param:{
      customerId:{
        idType: "${customerId.idType}"
          idValue: "${customerId.idValue}"
      }
      }
    ) {
      packageId
      name
      cartQuantity
      configOptions
      accessories
      packagePriceId
      subscriptionName
      configOptions
      accessories
      isSubscription
      shortName
      groupedName
      productCategory
      detailsHtml
      packagePrices {
        packageId
        packagePriceId
        name
        channelType
        priceType
        price
        recurrenceFrequency
        recurrenceCount
      }
      imageUrls {
        ordinal
        thumbnail
        large
        hero
        video
      }
      maxQuantity
      ordinal
      extendedSalesText
      packageShippingMethods {
        packageShippingMethodId
        packageId
        name
        channelType
        shippingMethod
        price
        fontAwesomeIcon
      }
      childPackages {
        packageId
        name
        shortName
        groupedName
        productCategory
        detailsHtml
        packagePrices {
          packagePriceId
          packageId
          name
          channelType
          priceType
          price
          recurrenceFrequency
          recurrenceCount
        }
        imageUrls {
          ordinal
          thumbnail
          large
          hero
          video
        }
        maxQuantity
        ordinal
        extendedSalesText
        packageShippingMethods {
          packageShippingMethodId
          packageId
          name
          channelType
          shippingMethod
          price
          fontAwesomeIcon
        }
      }
    }
  }
  `,
};

export const DocumentQuery = {
  Key: Queries.Documents,
  Query: () => `
  query {
    documents {
      url
      formName
      purpose
      returnInstructions
    }
  }
  `,
};

export const SubscriptionQuery = {
  Key: Queries.Subscriptions,
  Query: () => `
  query {
    customerSubscriptions {
    equipmentPackageId
    packagePriceId
    isActive
    subscriptionName
    recurrenceCount
    recurrenceFrequency
    createdBy
    createdUTC
    lastUpdatedBy
    lastUpdatedUTC
    lastProcessed     
    nextProcessDate
    skipNextOrder
    lastUpdatedUserEmail
  }
}
  `,
};

export const UserQuery: Query = {
  Key: Queries.User,
  Query: (invitationCode?: string) => `query {
    user (
      param: {
        oneTimeInvitationCode: "${invitationCode ?? ''}"
      }
    ) {
      ${UserQueryString}
  }
}`,
};

export const ImpersonateUserQuery = {
  Key: Queries.ImpersonateUser,
  Query: (targetEmail: string, targetCustomerId: CustomerId) => `
  query {
     impersonateUser (param: {
        targetEmail: "${targetEmail}",
        targetCustomerId: {
            idType: "${targetCustomerId.idType}",
            idValue: "${targetCustomerId.idValue}",
        }
    })
    {
        ${UserQueryString}
    }
  }`,
};

export const SearchUsersQuery = {
  Key: Queries.SearchUsers,
  Query: (query: string, skip: number, take: number) => `
  query {
    searchUsers(
        query: "${query}"
        skip: ${skip}
        take: ${take}      
    )
    {
        results
        {
            dbaName
            email
            identifier
            identifierType
            name
            permission
            status
        }
        totalRecords
    }
  }
  `,
};

export const OverrideTemplateQuery: Query = {
  Key: Queries.OverrideTemplate,
  Query: () => `
  query {
    overrideTemplates {
      ${UserQueryString}
    }
  }`,
};

export const FilteredCustomerAssignmentQuery = {
  Key: Queries.FilteredCustomerAssignment,
  Query: (filterText: string, page: number, limit: number) => `
  query {
    filteredAssignments(
      param: {
        filterText: ${JSON.stringify(filterText)}
        page: ${page}
        pageSize: ${limit}
      }
    )
    {
      totalRecords
      customerAssignments
      {
        accountEmail
        customerId {
          idType
          idValue
        }
        isOwner
        role
        dbaName
        city
        state
        status
      }
    }
  }
  `,
};

export const CustomerAddressQuery = {
  Key: Queries.CustomerAddress,
  Query: (customerId: CustomerId) => `
  query {
    customerAddress(
      param: {
        customerId: { idValue: "${customerId.idValue}", idType: "${customerId.idType}" }
      }
    ) {
      dba {
        name
        address
        city
        state
        zipCode
      }
      legal {
        name
        address
        city
        state
        zipCode
      }
    }
  }
  `,
};

export const CustomerAccountSettingsQuery = {
  Key: Queries.CustomerAccountSettings,
  Query: (customerId: CustomerId) => `
  query {
    customerAccountSettings(
      param: {
        customerId: { idValue: "${customerId.idValue}", idType: "${customerId.idType}" }
      }
    ) 
    {
      dbaName,
      dbaPhoneNumber,
      maskedEIN,
      ownerName,
      primaryContactCellPhone,
      primaryContactEmail,
      primaryContactWrkPhone,      
      dbaAddress {
        name,
        address,          
        city,
        state,
        zipCode
      },
      legalAddress {
        name,
        address,          
        city,
        state,
        zipCode
      }
      owners {
        fullName
      }
    }
  }
  `,
};

export const UserManagementQuery = {
  Key: Queries.UserManagement,
  Query: (
    filterText: string,
    showInactive: boolean,
    sortBy: string,
    sortDirection: ResultSortDirection,
    page: number,
    limit: number,
  ) => `
  query {
    userManagementData(
      param: {
        filterText: "${filterText}"
        showInactive: ${showInactive}
        sortBy: "${sortBy}"
        sortDirection: ${sortDirection}
        page: ${page}
        pageSize: ${limit}
      }
    )
    {
      totalRecords
      filteredResults
      {
        email
        name
        permissions
        status
        lastLoginUTC
      }
    }
  }
  `,
};

export const AvailableApplicationsQuery: Query = {
  Key: Queries.Dashboard,
  Query: () => `
  query {
    applications
    {
      name
      availableApplications {
        name
        description
        iconUrl
        navLinkUrl
        code
        isAssigned
        ordinal
      }
    }
  }`,
};

export const EquipmentInfoQuery = {
  Key: Queries.EquipmentInfo,
  Query: (name: string) => `
  query {
    equipmentDetails(
        param: {
            name: "${name}"
        }
    ) {
      isInCartOneTime
      isInCartSubscription
      hasActiveSubscription
      activeSubscription {
          subscriptionName,
          nextProcessDate,
          lastUpdatedUTC,
          createdUTC,
          skipNextOrder,
          recurrenceCount,
          equipmentPackageId,
          packagePriceId,
      }
      packageId,
      name,
      groupedName,
      allowSubscription,
      shortName,
      extendedSalesText,
      detailsHtml,
      salesText,
      hasAccessories,
      deviceDescription,
      features {
        ordinal,
        description,
        fontAwesomeIcon,
      },
      packageEquipment {
        equipmentId,
        ordinal,
        quantity,
        equipment{
            equipmentId,
            fullName,
            name,
            description,
            logisticsDID,
            equipmentConfigQuestions {
                equipmentConfigQuestionId,
                equipmentId,
                parentEquipmentConfigOptionId,
                label,
                ordinal,
                tooltipText,
                fontAwesomeIcon,
                fieldType,
                fieldDefinition,
                isRequired,
                isSharedPackageValue,
            },
            equipmentConnectivityFeatures {
              description,
              fontAwesomeIcon,
              backgroundColor,
              ordinal
            }
        }

      },
      packagePrices {
        packagePriceId,
        packageId,
        price,
        name
      },
      imageUrls {
        thumbnail
        large
        hero
        video
      },
      maxQuantity,
      ordinal,
      packageShippingMethods {
        packageShippingMethodId
        name
        price
      },
      packageAccessories {
        packageAccessoryId
        packageId
        equipmentId
        ordinal
        quantity
        equipment{
           equipmentId,
            fullName,
            name,
            description,
            logisticsDID,
            equipmentConfigQuestions {
                equipmentConfigQuestionId,
                equipmentId,
                parentEquipmentConfigOptionId,
                label,
                ordinal,
                tooltipText,
                fontAwesomeIcon,
                fieldType,
                fieldDefinition,
                isRequired,
                isSharedPackageValue,
            }
        }
        packageAccessoryPrices{
          packageAccessoryId
          packageAccessoryPriceId
          name
          price
          recurrenceFrequency
        }
      }
      childPackages {
        packageId,
        name,
        shortName,
        detailsHtml,
        packagePrices {
          packagePriceId,
          packageId,
          price,
          name
        },
        imageUrls {
          thumbnail
          large
          hero
          video
        }
        maxQuantity,
        ordinal,
        packageShippingMethods {
          packageShippingMethodId
          name
          price
        },
      }
	  }
  }
  `,
};

export const EquipmentDetailsQuery = {
  Key: Queries.EquipmentDetails,
  Query: (name: any) => `
  query {
    detailedEquipment(where:
    {or:[{name:{eq:"${name}"}}, {groupedName:{eq:"${name}"}}]
    }) {
      packageId,
      name,
      groupedName,
      allowSubscription,
      shortName,
      extendedSalesText,
      detailsHtml,
      salesText,
      hasAccessories,
      deviceDescription,
      features {
        ordinal,
        description,
        fontAwesomeIcon,
      },
      packageEquipment {
        equipmentId,
        ordinal,
        quantity,
        equipment{
            equipmentId,
            fullName,
            name,
            description,
            logisticsDID,
            equipmentConfigQuestions {
                equipmentConfigQuestionId,
                equipmentId,
                parentEquipmentConfigOptionId,
                label,
                ordinal,
                tooltipText,
                fontAwesomeIcon,
                fieldType,
                fieldDefinition,
                isRequired,
                isSharedPackageValue,
            },
            equipmentConnectivityFeatures {
              description,
              fontAwesomeIcon,
              backgroundColor,
              ordinal
            }
        }

      },
      packagePrices {
        packagePriceId,
        packageId,
        price,
        name
      },
      imageUrls {
        thumbnail
        large
        hero
        video
      },
      maxQuantity,
      ordinal,
      packageShippingMethods {
        packageShippingMethodId
        name
        price
      },
      packageAccessories {
        packageAccessoryId
        packageId
        equipmentId
        ordinal
        quantity
        equipment{
           equipmentId,
            fullName,
            name,
            description,
            logisticsDID,
            equipmentConfigQuestions {
                equipmentConfigQuestionId,
                equipmentId,
                parentEquipmentConfigOptionId,
                label,
                ordinal,
                tooltipText,
                fontAwesomeIcon,
                fieldType,
                fieldDefinition,
                isRequired,
                isSharedPackageValue,
            }
        }
        packageAccessoryPrices{
          packageAccessoryId
          packageAccessoryPriceId
          name
          price
          recurrenceFrequency
        }
      }
      childPackages {
        packageId,
        name,
        shortName,
        detailsHtml,
        packagePrices {
          packagePriceId,
          packageId,
          price,
          name
        },
        imageUrls {
          thumbnail
          large
          hero
          video
        }
        maxQuantity,
        ordinal,
        packageShippingMethods {
          packageShippingMethodId
          name
          price
        },
      }
	  }
  }
  `,
};

export const EquipmentStaticList = `
query {
  equipment{
    name
    groupedName
  }
}
`;

export const EquipmentPackageQuery: Query = {
  Key: Queries.EquipmentPackage,
  Query: (packageId: number) => `
  query {
	  equipmentPackage(id:${packageId}){
      packageId,
      name,
      groupedName,
      allowSubscription,
      shortName,
      extendedSalesText,
      detailsHtml,
      packageEquipment {
        equipmentId,
        ordinal,
        quantity,
        equipment{
            equipmentId,
            fullName,
            name,
            description,
            logisticsDID,
            equipmentConfigQuestions {
                equipmentConfigQuestionId,
                equipmentId,
                parentEquipmentConfigOptionId,
                label,
                ordinal,
                tooltipText,
                fontAwesomeIcon,
                fieldType,
                fieldDefinition,
                isRequired,
                isSharedPackageValue,
            }                    
        }

      },
      packagePrices {
        packagePriceId,
        packageId,
        price,
        name
      },
      imageUrls {
        thumbnail
        large
        hero
        video
      },
      maxQuantity,
      ordinal,
      packageShippingMethods {
        packageShippingMethodId
        name
        price
      },
      childPackages {
        packageId,
        name,
        shortName,
        detailsHtml,
        packagePrices {
          packagePriceId,
          packageId,
          price,
          name
        },
        imageUrls {
          thumbnail
          large
          hero
          video
        }
        maxQuantity,
        ordinal,
        packageShippingMethods {
          packageShippingMethodId
          name
          price
        },
      }
	  }
  }`,
};

export const EquipmentQuery: Query = {
  Key: Queries.Equipment,
  Query: () => `
  query {
	  equipment{
      packageId,
      name,
      groupedName,
      productCategory,
      allowSubscription,
      packagePrices {
        packageId,
        price,
      },
      imageUrls {
        thumbnail
      },
      ordinal,
      childPackages {
        packageId,
        name,
        packagePrices {
          price,
        },
        ordinal,
      }
	  }
	}`,
};

export const LocationQuery: Query = {
  Key: Queries.LocationSearch,
  Query: (value: any) => `
  query {
    searchAddresses(value:"${value}") {
      addressSummary
      streetAddress
      city
      stateAbbr
      stateName
      zipCode
    }
  }
  `,
};

export const GetOpenBatchSummariesQuery = {
  Key: Queries.GetOpenBatchSummaries,
  Query: (
    range: BatchRange,
    filterText?: string | null,
    minDate?: string | null,
    maxDate?: string | null,
    terminalId?: Array<string> | null,
  ) => {
    return `
  query {
    merchantBatchSummaries(
      param: {
        minDate: ${minDate != null ? '"' + minDate + '"' : null}
        maxDate: ${maxDate != null ? '"' + maxDate + '"' : null}
        batchNumber: "Open"
        terminalIds: [${terminalId!.map((terminal) => `"${terminal}"`).join(', ')}]
        terminalDescription: "${filterText ?? ''}"
        range:${range}
      }
    )
    {
      merchantNumber
      terminalNumber
      terminalDescription
      batchId
      batchNumber
      transactionCount
      netAmount
      batchCreateDate
      batchClosedDate
      batchStatus
      totalRecordCount
    }
  }
  `;
  },
};

export const GetClosedBatchSummariesQuery = {
  Key: Queries.GetClosedBatchSummaries,
  Query: (
    range: BatchRange,
    filterText?: string | null,
    minDate?: string | null,
    maxDate?: string | null,
    terminalId?: Array<string> | null,
  ) => {
    return `
  query {
    merchantBatchSummaries(
      param: {
        minDate: ${minDate != null ? '"' + minDate + '"' : null}
        maxDate: ${maxDate != null ? '"' + maxDate + '"' : null}
        batchNumber: null
        terminalIds: [${terminalId!.map((terminal) => `"${terminal}"`).join(', ')}]
        terminalDescription: "${filterText ?? ''}"
        range: ${range}
        closedBatchesOnly: true
      }
    )
    {
      merchantNumber
      terminalNumber
      terminalDescription
      batchId
      batchNumber
      transactionCount
      netAmount
      batchCreateDate
      batchClosedDate
      batchStatus
      totalRecordCount
    }
  }
  `;
  },
};

export const SalesRepresentativeQuery = {
  Key: Queries.GetSalesRepresentative,
  Query: () => `
  query {
    salesRepresentative {
        firstName
        lastName
        email
        phoneNumber
    }
  }`,
};

export const ClosedBatchDetailsQuery = {
  Key: Queries.ClosedBatchDetails,
  Query: (batchNumber: string | null, batchId: string | null, transactionDate: string | null) => {
    return `
  query {
    closedBatchDetails(
      param: {
        batchNumber: ${batchNumber != null ? '"' + batchNumber + '"' : null}
        batchId: ${batchId != null ? '"' + batchId + '"' : null}
        transactionDate: ${transactionDate != null ? '"' + transactionDate + '"' : null}
      }
    )
    {
      merchantNumber
      batchId
      batchNumber
      transactionStatus
      authCode
      responseCode
      connectivity
      transactionType
      icQualification
      invoiceNumber
      cardType
      cardProduct
      authAmount
      amount
      tipAmount
      settledAmount
      settledDateUTC
      transactionDateUTC
      transactionUUID
      cardNumberMasked
      cardExpirationDate
      transactionCode
      arn
      terminalNumber
      authDateUTC
    }
  }
  `;
  },
};

export const GetTerminalNumbers = {
  Key: Queries.GetTerminalNumbers,
  Query: (range: BatchRange, minDate?: string | null, maxDate?: string | null) => `
  query {
    terminalNumbers(
      param: {
        range:${range}
        minDate: ${minDate != null ? '"' + minDate + '"' : null}
        maxDate: ${maxDate != null ? '"' + maxDate + '"' : null}
      }
    )
    {
      terminalNumber,
      terminalDescription
    }
  }
  `,
};

export const GetOpenBatchAmountQuery = {
  Key: Queries.GetOpenBatchAmount,
  Query: () => `
  query {
    openBatchSummary {
        lastUpdate
        totalAmount
    }
  }`,
};

export const GetStatementReportQuery = {
  Key: Queries.GetStatementReportData,
  Query: (range: StatementRange, minDate?: string, maxDate?: string) => `
  query {
    statementReportData(
    param: {
      range:${range}
      customMinDate: ${minDate != null ? '"' + minDate + '"' : 'null'}
      customMaxDate: ${maxDate != null ? '"' + maxDate + '"' : 'null'}
    }
  )
    {
      statementMonth
      statementYear
      transactionCount
      monthlyVolume
      totalFees
      processor
    }
  }`,
};
